import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faPhone,
    faMapMarker
} from '@fortawesome/free-solid-svg-icons'
import GatsbyImage from 'gatsby-image'

class ContactRoute extends React.Component {
    render() {
        const contactPageData = this.props.data
        const title = contactPageData.kontentItemSiteMetadata.elements.title.value
        const kontentItemContactPage = contactPageData.kontentItemContactPage
        const elements = kontentItemContactPage.elements
        var subtitle = elements.title.value
        var description = elements.short_description.value
        var email = elements.email.value
        var emailTo = "mailto:" + email
        var phone = elements.phone.value
        var callTo = "tel:" + phone
        const personalPhoto = elements.personal_photo
        var route = elements.route.value
        const routeImage = elements.route_image
        var billingInfo = elements.billing_info.value

        return (
            <Layout>
                <div>
                    <Meta title={subtitle} postfix={title} description={description} />
                    <Header isContactPage />
                    <PageHeading data={subtitle} />

                    <section className="contact-info">
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-lg-4">
                                    <div className="contact-info__personal-photo">
                                        {personalPhoto.value[0] ? (
                                            <GatsbyImage fluid={personalPhoto.value[0].fluid} />
                                        ) : ""}
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <a href={emailTo} data-tracking-label="Contact_Email">
                                                <div className="info-item info-item__email">
                                                    <div className="icon">
                                                        <FontAwesomeIcon icon={faEnvelope} size="1x" />
                                                    </div>
                                                    <h2>Email</h2>
                                                    <p>{email}</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-lg-12">
                                            <a href={callTo} data-tracking-label="Contact_Phone">
                                                <div className="info-item info-item__tel">
                                                    <div className="icon">
                                                        <FontAwesomeIcon icon={faPhone} size="1x" />
                                                    </div>
                                                    <h2>Telefon</h2>
                                                    <p>{phone}</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-item info-item--static">
                                        <div className="icon">
                                            <FontAwesomeIcon icon={faMapMarker} size="1x" />
                                        </div>
                                        <h2>Adresa a fakturační údaje</h2>
                                        <RichTextElement
                                            value={billingInfo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="map" id="mapa">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner-content">
                                        <div className="block-heading">
                                            <h2>Najděte si ke mně cestu na mapě</h2>
                                        </div>
                                        <div>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.0258499892084!2d16.611444615686477!3d49.20006917932241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4712945948ce4e8f%3A0xa148ae39ad4698f7!2zUMWZw61rb3AgODQzLCA2MDIgMDAgQnJuby1zdMWZZWQtWsOhYnJkb3ZpY2U!5e0!3m2!1sen!2scz!4v1605217434120!5m2!1sen!2scz" allowFullScreen=""></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="route">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-heading">
                                        <h2>Příjezdová cesta</h2>
                                    </div>
                                    <h3>Jak se ke mně dostanete</h3>
                                    <RichTextElement
                                        value={route} />
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="route-thumb">
                                        {routeImage.value[0] ? (
                                            <GatsbyImage fluid={routeImage.value[0].fluid} />
                                        ) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer isContactPage />
                </div>
            </Layout>
        )
    }
}

export default ContactRoute

export const pageQuery = graphql`
  query ContactQuery($codename: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    kontentItemContactPage(system: {codename: {eq: $codename}}) {
      id
      system {
        id
      }
      elements {
        title {
          value
        }
        short_description {
          value
        }
        email {
          value
        }
        phone {
          value
        }
        personal_photo {
          value {
            fluid(maxWidth: 600) {
              ...KontentAssetFluid
            }
            name
            url
          }
        }
        route {
          value
        }
        route_image {
          value {
             fluid(maxWidth: 600) {
              ...KontentAssetFluid
           }
           name
            url
          }
        }
        billing_info {
          value
        }
      }
    }
  }
`